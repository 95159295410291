import { useState, useEffect } from "react";
//import { useRef } from "react";
//import { useInView } from "framer-motion";

//import { managementTeam } from "constant/managementTeam";
//import PopupManagement from "components/ManagementTeam/PopupManagement";

import "components/ManagementTeam/managementTeam.scss";
import "animate.css";

const ManagementTeam = () => {
//  const mission = useRef();
 // const missionInView = useInView(mission, { amount: 0.1, once: true });
 // const missionContentInView = useInView(mission, { amount: 0.2, once: true });
  //const [selectTeamMember, setSelectTeamMember] = useState();
  const [selectTeamMember] = useState();

/*  const handleCardClick = (teamMember) => {
    setSelectTeamMember(teamMember);
  }; */

  useEffect(() => {
    document.body.style.overflow = selectTeamMember ? "hidden" : "auto";
  }, [selectTeamMember]);

  /*return (
    <section className="ourTeam" ref={mission}>
      <div className="container ourTeamContainer">
        <h3
          className={
            missionInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutUp"
          }
        >
          Our Team
        </h3>
        <p
          className={
            missionInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutUp"
          }
        >
          Our Management Team
        </p>

        <div className="ourTeamManagers">
          {managementTeam.map((teamMember, index) => (
            <div
              className={`ourTeamManager ${
                missionContentInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutBottom"
              }`}
              key={index}
              onClick={() => handleCardClick(teamMember)}
            >
              <img src={teamMember?.image} alt="jonPierre" />
              <h4>{teamMember.name}</h4>
              <p>{teamMember.designation}</p>
            </div>
          ))}
        </div>
      </div>

      {selectTeamMember && (
        <PopupManagement
          teamMember={selectTeamMember}
          onClose={() => setSelectTeamMember(null)}
        />
      )}
    </section>
  );*/
};

export default ManagementTeam;
